<template>
  <v-container>
    <h1>{{ $t("upload.header") }}</h1>
    <v-file-input
      v-model="file"
      accept=".csv"
      :loading="$store.state.upload.status === 'fetching'"
      :label="$t('upload.placeholder')"
      show-size
    ></v-file-input>
    <v-btn color="primary" @click="onUpload" :disabled="!file">{{
      $t("upload.button")
    }}</v-btn>
    <v-dialog v-model="dialog">
      <v-card height="20vh">
        <v-card-title
          style="word-break: break-word"
          class="justify-center"
          v-if="this.$store.state.upload.status === 'success'"
          >{{ $t("upload.successful") }}</v-card-title
        >
        <v-card-title v-else>{{ this.$store.state.upload.error }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" color="primary">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import router from "@/router";
export default {
  data: () => ({
    file: null,
    dialog: false,
  }),
  methods: {
    onUpload() {
      this.$store.dispatch("upload/parseCSV", { file: this.file });
      this.file = null;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (
      router.app.$store.state.profile &&
      router.app.$store.state.profile.data &&
      router.app.$store.state.profile.data.admin === true
    ) {
      next();
    } else {
      next({ name: "home" });
    }
  },
  watch: {
    "$store.state.upload.status"(val) {
      if (val !== "fetching") {
        this.dialog = true;
      }
    },
  },
};
</script>
